<template>
  <div>
    <b-card-title class="font-weight-bolder d-flex flex-wrap align-items-baseline" v-if="!hideTitle">
      <feather-icon v-if="showBack" size="24" class="mr-1" icon="ArrowLeftIcon" @click="$router.back()"/>
      <div class="d-flex align-items-center mr-1">
        {{ title }}
        <b-badge v-if="titleBadge.text" class="ml-1" :variant="titleBadge.variant">{{ titleBadge.text }}</b-badge>
      </div>
      <div v-if="subTitle" class="font-weight-bold font-small-4 mt-05" :class="subTitleClass">{{ subTitle }}</div>
    </b-card-title>

    <b-row v-if="showActionRow">
      <b-col sm="12" md="12" lg="6" class="mb-1">
        <b-input-group class="cursor-pointer" v-if="!hideSearch">
          <b-form-input :placeholder="searchTips" v-model="searchContent" @keyup.enter="runSearch"/>
          <b-input-group-append @click="runSearch">
            <b-button variant="primary">
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col v-if="buttonActions.length > 0" sm="12" md="12" lg="6" class="d-flex justify-content-end mb-1">
        <template v-for="item in buttonActions">
          <button-permission
            :variant="item.variant"
            class="ml-1"
            :class="item.class"
            @click="item.fun"
            :permission="item.permission"
            :style="item.style"
          >
            {{ common.getI18n(item.text) }}
          </button-permission>
        </template>
      </b-col>
    </b-row>

  </div>

</template>

<script>
import {
  BFormGroup, BFormInput, BButton, BRow, BCol, BCardTitle,BCardHeader, BInputGroup, BInputGroupAppend, BInputGroupPrepend
} from 'bootstrap-vue'
import ButtonPermission from "@/components/ButtonPermission";
import common from "@/common";

export default {
  name: "TableHeader",
  components: {
    ButtonPermission,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BCardTitle,
    BCardHeader,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend
  },
  props: {
    title: {type: String, default: ''},
    titleBadge: {
      type: Object,
      default() { return {
        text: '',
        variant: 'secondary'
      }
    }},
    subTitle: { type: String, default: '' },
    subTitleClass: { type: String, default: '' },
    buttonActions: {type: Array, default: () => []},
    searchTips: {type: String, default: ''},
    hideTitle: {type: Boolean, default: false},
    hideSearch: {type: Boolean, default: false},
    showBack: {type: Boolean, default: false}
  },
  data() {
    return {
      searchContent: '',
      search_content: '',
      common,
    }
  },
  computed: {
    showActionRow() {
      return !this.hideSearch || this.buttonActions.length > 0
    }
  },
  methods: {
    buildSearchContent() {
      this.search_content = this.searchContent
    },
    runSearch: function () {
      this.$emit('runSearch')
    }
  }
}
</script>

<style scoped>

</style>
